import data from "../data/data"
export default {
    data: function () {
        return {
            step: 1,
            fwHeader: data.fwHeader,
            stepList: data.stepList,
            second:60,
            cutdown:false,
            logourl:''
        }
    },
    created(){
        this.getLogo();
    },
    methods: {
        toLogin:function(){
            this.$router.replace('/login')
        },
        getLogo:function(){
            this.$post('/api/base/getLogo.html', {}).then((res) => {
                console.log(res)
                if(res.code == 200){
                   this.logourl = res.url || ''
                }else{
                    return this.$message(res.msg); 
                }
            }).finally(() => {
        
            })
        },
        submitForm() {

        },
        backIndex: function () {
            this.$router.replace('/')
        },
        countDown:function(){
            this.cutdown = true
        let setInt =  setInterval(()=>{
                this.second--
                console.log(this.second)
                if(this.second == 0){
                    console.log(3123)
                    clearInterval(setInt)
                    this.second = 60;
                    this.cutdown = false;
                }

            },1000)
        },
        getCode:function(){
            if( this.cutdown)return
            this.countDown();
            this.$post('/api/user/sendSms.html',{phone:this.mobile} ).then((res) => {
                if(res.code == 200){
              
                }else{;
             
                }
                return this.$message(res.msg); 
            }).finally(() => {

            })
        }
    }

}