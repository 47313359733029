export default{
    fwHeader:[
        {
            text:'加工门类/产品种类',
            id:1
        },{
            text:'厂房面积',
            id:2
        },{
            text:'机器数量',
            id:3
        },{
            text:'工人数量',
            id:4
        },{
            text:'备注',
            id:5
        },{
            text:'图片',
            id:6
        },{
            text:'操作',
            id:7
        },
    ],
    stepList: [
        {
            id: 1,
            stepName: '1.账号注册'
        }, {
            id: 2,
            stepName: '2.完善资料'
        }, {
            id: 3,
            stepName: '3.完成'
        }
    ],
}