
export default {
    data: function () {
        return {
            roleList: [{
                value: 1,
                label: '个人用户'
            }, {
                value: 2,
                label: '企业用户'
            }, {
                value: 3,
                label: '供应商'
            }],
            value: '',
        }
    },
    created(){
    },
    methods: {
        // getRoleList: function () {
        //     this.$post('/api/user/roleList.html',{}).then((res) => {
        //         console.log(res)
        //     }).finally(() => {

        //     })
        // }
    }
}