export default {
    data: function () {
        return {
            mobile: '',
            password: '',
            code: '',
            hint:'',
            verifi:false,
            radio:'1',
            name:''
        }
    },
    methods:{ 
        success:function(){
            console.log(this.$store.state)
            this.verifi = true
        },
        register:function(){
            let hint = this.hint;
            var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6,18}');
            if(!this.verifi){
                hint = '请完成滑动验证'
            }
            if(this.mobile == ''){
                hint = '请输入正确的手机号'
            }
            if(this.value == ''){
                hint = '请选择用户身份'
            }
            if(this.code == ''){
                hint = '请输入正确的验证码'
            }
            if(!pwdRegex.test(this.password)){
                hint = '密码格式不正确'
            }
            if(this.value == 1){
                if(this.name == ''){
                    hint = '请填写用户名' 
                }
            }else{
                if(this.enterprise_contact == ''){
                    hint = '请填写企业联系人' 
                }
                if(this.enterprise_name == ''){
                    hint = '请填写企业名称' 
                }
            }
            if(hint != ''){
              return this.$message(hint);
            }
           

            let formData;
            if(this.value == 1){
                formData =  {
                    mobile: this.mobile,
                    role_id:this.value,
                    password:this.password,
                    code:this.code,
                    name:this.name,
                }
            }else{
                formData =  {
                    mobile: this.mobile,
                    role_id:this.value,
                    password:this.password,
                    code:this.code,
                    name:this.enterprise_contact,
                    enterprise_name:this.enterprise_name
                } 
            }
            this.$post('/api/user/register.html', formData).then((res) => {
                console.log(res)
                if(res.code == 200){
                    if(this.value != 3){
                        localStorage.setItem('user',JSON.stringify(res.data))
                        localStorage.setItem('yhtoken',res.data.token)
                        localStorage.setItem('isLogin',1);
                        this.$store.commit('changeLogin',1)
                    }

                    this.upData.token = res.data.token
                    if(this.value == 1){
                        this.step = 3;
                        let setInt = setInterval(() => {
                            this.tzsecond--
                            if (this.tzsecond == 0) {
                                clearInterval(setInt)
                                this.$router.replace('/')
                            }
    
                        }, 1000)
                    }else{
                        this.step = 2;
                    }
                   
                }else{
                    if(res.code == 401){
                        let userInfo = res.data;
                        console.log(userInfo, 123123);
                        this.enterprise_name = userInfo.enterprise_name
                        this.enterprise_mobile = userInfo.enterprise_mobile
                        this.enterprise_addr = userInfo.enterprise_addr
                        this.enterprise_email = userInfo.enterprise_email
                        this.enterprise_contact = userInfo.enterprise_contact
                        this.contact_job = userInfo.contact_job
                        this.enterprise_createtime = userInfo.enterprise_createtime
                        this.enterprise_numbers = userInfo.enterprise_numbers
                        // this.license_img = userInfo.license_img
                        this.usertype = userInfo.user_type
                        this.license_img = '';
                        this.three_img = userInfo.three_img;
                        this.iso_img = userInfo.iso_img;
                        this.rohs_img =userInfo.rohs_img;
                        this.ce_img =userInfo.ce_img;
                        this.secrecy_img = userInfo.secrecy_img;
                        this.is_secrecy = userInfo.secrecy_img == "" ? '1':'2'
                        this.business_range = userInfo.business_range;
                        let business_info = userInfo.business_info
                        for(let i = 0;i< business_info.length;i++){
                            let obj = {
                                product:'',
                                area:'',
                                machine_nums:'',
                                workers:'',
                                marks:'',
                                imgs:[],
                            };

                            obj.product = business_info[i].category_name;
                            obj.area = business_info[i].plant_area;
                            obj.machine_nums = business_info[i].parseJSON;
                            obj.workers = business_info[i].workers_nums;
                            obj.marks = business_info[i].marks;
                            obj.imgs = JSON.parse(business_info[i].imgs);
                            if(this.type){
                                this.htList[this.index]  = obj;
                            }else{
                                this.htList.push(obj);
                            }
                        }
                        this.upData.token = res.data.token
                        this.step = 2;
                    }
                }
                //return this.$message(res.msg);
            }).finally(() => {
        
            })
        }
    }
}